@import '../../../../styles/variaveis.scss';

.destaqueslist{
    border: 1px solid #BED2E0;
    background-color: #F2F8F9;
    @media (max-width: $brackpoint-md) {
        width: 90%;
    }
    
    .total{
        border: 1px solid #CFDCDF;
        background-color: #FFFFFF;
    }
    .title{
        display: flex;
        padding: 21px;
        font-size: 12px;
        align-items: center;
        img{
            height: 20px;
            width: 20px;
            margin: 0px 12px 0px 0px;
        }
        font-size: 12px;
    }
    .total{
        padding: 4.5px 21px;
    }
    .lista{
        padding: 4.5px 21px;
    }
}