@import 'variaveis';


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// botoes material
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.MuiButton-contained {
    box-shadow: none !important;
    color: $cor-white !important;
    background-color: $cor-black !important;

    &:hover {
        background-color: lighten($cor-black, 10%) !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        background-color: rgba( $cor-black, .1 ) !important;
    }
}
.MuiInputBase-root.Mui-disabled {
    
    background: #f1f1f187
}
.MuiButton-containedPrimary {
    color: $cor-white;
    background-color: $cor-principal !important;

    &:hover {
        background-color: lighten($cor-principal, 10%) !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        background-color: rgba( $cor-black, .1 ) !important;
    }
}

.MuiButton-containedSecondary {
    color: $cor-black !important;
    background-color: $cor-secondary !important;

    &:hover {
        background-color: desaturate($cor-secondary, 20%) !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        background-color: rgba( $cor-black, .1 ) !important;
    }
}

.MuiButton-outlined {
    color: $cor-black !important;
    border: 1px solid $cor-black !important;

    &:hover {
        background-color: lighten($cor-black, 10%) !important;
        color: $cor-white !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        border: 1px solid rgba( $cor-black, .2 ) !important;
    }
}

.MuiButton-outlinedPrimary {
    color: $cor-principal !important;
    border: 1px solid $cor-principal !important;

    &:hover {
        color: $cor-white !important;
        background-color: lighten($cor-principal, 10%) !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        border: 1px solid rgba( $cor-black, .2 ) !important;
    }
}


.MuiButton-outlinedSecondary {
    color: $cor-secondary !important;
    border: 1px solid $cor-secondary !important;

    &:hover {
        color: $cor-black !important;
        background-color: $cor-secondary !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        border: 1px solid rgba( $cor-black, .2 ) !important;
    }
}

.MuiButton-textPrimary {
    color: $cor-principal !important;

    &:hover {
        background-color: rgba( $cor-principal, .1 ) !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
    }
}

.MuiButton-textSecondary {
    color: $cor-secondary !important;

    &:hover {
        background-color: rgba( $cor-secondary, .1 ) !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
    }
}

.MuiButtonBase-root {
    border-radius: 8px !important;

    &.danger {
        color: $cor-white !important;
        background-color: $cor-destaque-danger !important;

        &:hover {
            background-color: lighten( $cor-destaque-danger, 10%) !important;
        }
    }
    &.secondary {
        color: $cor-black !important;
        background-color:$cor-secondary !important;

        &:hover {
            background-color: lighten( $cor-secondary, 10%) !important;
        }
    }
}

.MuiIconButton-outlined {
    color: $cor-black !important;
    border: 1px solid $cor-black !important;

    &:hover {
        background-color: lighten($cor-black, 10%) !important;
        color: $cor-white !important;
    }

    &.Mui-disabled {
        color: rgba( $cor-black, .2 ) !important;
        border: 1px solid rgba( $cor-black, .2 ) !important;
    }

   
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// form material
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.MuiFormControl-root {
    width: 100% !important;
    margin: 10px 0 20px 0 !important;

    &.MuiTextField-root {
        margin: 0 !important;
    }

    &.noMargin {
        margin: 0px !important;
    }
}

.MuiInputAdornment-filled {
    &.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
        margin-top: 0px !important;
        color: $cor-gray-50;
    }
}

.MuiFormLabel-root {
    &.Mui-focused {
        color: $cor-principal !important;
    }
}

.MuiFilledInput-underline {
    &:after {
        border-bottom: 2px solid $cor-principal !important;
    }
    &.Mui-error:after {
        border-bottom-color: #f44336 !important;
    }
}



.MuiAppBar-colorPrimary {
    background-color: $cor-principal !important;
}

.MuiCheckbox-colorSecondary {
    color: $cor-secondary !important;
}

.MuiCheckbox-colorSecondary.Mui-disabled {
    color: $cor-gray-50 !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent !important;
}

.MuiCheckbox-root.Mui-disabled {
    opacity: 0.5 !important;
}


.MuiDialogTitle-root button {
    position: absolute;
    top: 4px;
    right: 4px;
}

.MuiDialog-paper {
    background-color: #fff !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: $cor-principal !important
}

.MuiLinearProgress-colorPrimary {
    background-color: lighten($cor-principal, 50%) !important;
}

.MuiAutocomplete-popper {
    ul {
        font-size: 12px !important;
    }

    img {
        max-width: 38px;
        margin-right: 6px;
    }
}

.MuiTab-root {
    min-height: 40px !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    transform: translate(12px, 7px) scale(0.65) !important;
}

.MuiChip-root {
    height: 25px !important;
}

.MuiFormLabel-root {
    font-size: 14px !important;
}

.MuiFilledInput-root {
    background-color: rgba(0, 0, 0, 0.0) !important;
    border: #eaeaea 1px solid;
}
.MuiFilledInput-root.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.1)  !important;
}


.noExcludeBtn .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
    display: none;
}

.MuiAutocomplete-tag {
    margin: 2px !important;
    max-width: calc(100% - 70px) !important;
}

@media screen and (max-width: 768px) {
    .MuiAutocomplete-tag {
        margin: 2px !important;
        max-width: 400px !important;
    }
}
@media screen and (max-width: 580px) {
    .MuiAutocomplete-tag {
        margin: 2px !important;
        max-width: 300px !important;
    }
}
@media screen and (max-width: 480px) {
    .MuiAutocomplete-tag {
        margin: 2px !important;
        max-width: 200px !important;
    }
}
@media screen and (max-width: 370px) {
    .MuiAutocomplete-tag {
        margin: 2px !important;
        max-width: 120px !important;
    }
}


.MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
        font-size: 12px;
    }
    
}


.MuiFormHelperText-root {
    &.danger {
        color: red !important;
    }
}

