@import 'variaveis';

@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import 'button';
@import 'material';
@import 'tables';
@import 'lists';
@import 'antCalendar';

body {
    margin: 0;
    font-size: 14px;
    font-family: $font-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f9f9 !important;
}

img.responsive {
    max-width: 100%;
}
strong {
    font-weight: 600;
}

p {
    font-size: 14px;
}

*{
    font-family: Arial, Helvetica, sans-serif;
}

h3 {
    color: $cor-principal !important;
    font-weight: normal !important;
    strong {
        font-weight: bold;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// diagramacao
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.vh100 {
    height: 100vh;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// espa�amentos
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.noGutter {
    padding: 0px !important;
}

.wrarpText {
    overflow-wrap: break-word;
}

.m0 {
    margin: 0px !important;
}

.m5 {
    margin: 5px !important;
}

.m10 {
    margin: 10px !important;
}

.m20 {
    margin: 20px !important;
}

.m30 {
    margin: 30px !important;
}

.mt4 {
    margin-top: 4px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt80 {
    margin-top: 80px !important;
}
.mb0 {
    margin-bottom: 0px !important;
}

.mb5 {
    margin-bottom: 5px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb80 {
    margin-bottom: 80px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt20 {
    margin-top: 20px !important;
}


.ml5 {
    margin-left: 5px !important;
}
.ml10 {
    margin-left: 10px !important;
}

.ml20 {
    margin-left: 20px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.mr4 {
    margin-right: 4px !important;
}

.mr5 {
    margin-right: 5px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr20 {
    margin-right: 20px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.pad0 {
    padding: 0px !important;
}

.pad5 {
    padding: 5px !important;
}

.pad10 {
    padding: 10px !important;
}

.pad20 {
    padding: 20px !important;
}
.pad40 {
    padding: 40px !important;
}

.padL0 {
    padding-left: 0px !important;
}

.padL4 {
    padding-left: 4px !important;
}

.padL5 {
    padding-left: 5px !important;
}

.padL10 {
    padding-left: 10px !important;
}

.padL20 {
    padding-left: 20px !important;
}
.padL40 {
    padding-left: 40px !important;
}
.padL60 {
    padding-left: 60px !important;
}
.padL80 {
    padding-left: 80px !important;
}
.padR0 {
    padding-right: 0px !important;
}

.padR4 {
    padding-right: 4px !important;
}

.padR5 {
    padding-right: 5px !important;
}

.padR10 {
    padding-right: 10px !important;
}

.padR20 {
    padding-right: 20px !important;
}
.padR40 {
    padding-right: 40px !important;
}
.padR60 {
    padding-right: 60px !important;
}
.padR80 {
    padding-right: 80px !important;
}

.padT0 {
    padding-top: 0px !important;
}

.padB10 {
    padding-bottom: 10px !important;
}

.padB0 {
    padding-bottom: 0px !important;
}

.padT10 {
    padding-top: 10px !important;
}
.padT20 {
    padding-top: 20px !important;
}
.padT40 {
    padding-top: 40px !important;
}
.padT60 {
    padding-top: 60px !important;
}
.padT80 {
    padding-top: 80px !important;
}
.padB20 {
    padding-bottom: 20px;
}
.padB120 {
    padding-bottom: 120px;
}

.padB120 {
    padding-bottom: 120px;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
    &.verticalCenter {
        align-items: center;
    }
    &.center {
        justify-content: center;
    }
}
.flexSB, .f-sb {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
}
.flexEnd {
    align-items: flex-end;
}
.flexColumn {
    flex-direction: column;
}

h1 {
    font-weight: normal;
    font-size: 24px;
    color: $cor-principal;
    margin-bottom: 20px;
    strong {
        font-weight: 600;
    }
}

h2 {
    font-size: 22px;
    color: $cor-principal;
    font-weight: normal;
    strong {
        font-weight: 600;
    }
}

h3 {
    font-weight: normal;
    font-size: 22px;
    margin-bottom: 20px;
    strong {
        font-weight: 600;
    }
}

.pageHeader {
    padding-top: 60px;
    text-align: left;

    &.noBorder {
        border: none
    }
}





.colorPrincipal {
    color: $cor-principal
}

.readOnly {
    label {
       font-size: 12px; 
       padding: 0px;
       margin: 0px;
       &.big {
           font-size: 18px;
       }
    }
    p {
        &.big {
            font-size: 22px;
        }
        &.mid {
            font-size: 18px;
        }
    }
    &.big {
        label {
            font-size: 18px;
        }
        p {
            font-size: 22px;
        }
    }
    
}

.fakeBtnOutline {
    border: 1px solid $cor-gray-25;
    padding: 8px 16px;
    display: inline-block;
}

.borderTop {
    border-top: 1px solid $cor-gray-25;
    padding-top: 20px;
    margin-top: 20px;
}

@media (max-width: $brackpoint-md) {
    .pageHeader {
        padding-top: 20px;
    }
}


.readOnlyBox {
   padding: 16px 22px;
   border: 2px solid $cor-gray-25;
   background: #fff;

   label {
       font-size: 18px;
       padding: 0;
       margin: 0;
   }

   p {
       font-size: 22px;
       padding: 0;
       margin: 0;
   }

   &.noBorder {
    border-color: transparent;
    background: transparent;
   }
   &.solid {
    border: none;
    background: $cor-gray-10;
   }
}


.containerBgWhite {
    background-color: #fff;
    padding: 40px 0;
}

.faixaTopDivider {
    border-top: 6px solid $cor-secondary;
}

.btnVoltar {
    border-radius: 5px;
    border: 2px solid #5CCBE1;
    color: #5CCBE1;
}

.btnVoltar:hover {
    border-radius: 5px;
    border: 2px solid #5CCBE1;
    color: #5CCBE1;
}

.btnCustomSecond{
    color: #fff;
    background-color: #5ccbe1;
    border-radius: 5px;
    font-size: 14px;
    &:hover{
        color: #fff;
    }
}

.avisoCampo {
    color: #e9910c;
    font-weight: bold;
    font-size: 15px;
    
  }