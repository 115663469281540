@import 'variaveis';



.ant-picker-calendar-date-content{
    ul {
        &.events {
            margin: 0px;
            padding: 0px;

            li {
                display: flex;
                justify-content: space-between;
                border: 2px solid $cor-principal;
                color: $cor-principal;
                border-radius: 20px;
                padding: 0 6px;
                text-align: center;
                font-size: 12px;
                align-items: center;
            }
        }
    }
}

.calendarTheme {
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
        border: 1px solid #f0f0f0;
        margin: 0px;
    }

    .ant-picker-calendar {
        background: transparent;
    }
    .ant-picker-calendar-full .ant-picker-panel {
        background: transparent;
    }

    .ant-badge-status-success {
        display: none;
    }

    .ant-badge {
        border: 2px solid $cor-principal;
        color: $cor-principal;
        border-radius: 20px;
        padding: 0 10px;
    }

    .ant-badge-status-text {
        margin-left: 0px;
        font-size: 12px;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        height: 60px;
    }
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: transparent !important;
    box-shadow: inset 0px 0px 0px 3px #fecc16;
}

.ant-picker-cell-disabled {
    background: #f9f9f9 !important;
}