@import 'variaveis';


.btn {
    font-size: 14px;
    border-radius: 0px;

    &.btn-icon {
        color: $cor-principal;
        padding: 0px 8px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &.btn-outline {
        border: 1px solid $cor-white;
        color: $cor-white;
        &:hover {
            border: 1px solid $cor-white;
            background-color: $cor-white;
            color: $cor-principal-dark;
        }
    }

    &.btn-fill {
        border: 1px solid $cor-white;
        background: $cor-white;
        color: $cor-principal-dark;
        &:hover {
            border: 1px solid $cor-secondary;
            background-color: $cor-secondary;
            color: $cor-principal-dark;
        }
    }

    &.btn-info {
        background: #7038FF; 
        border: none;

        &:hover {
            background: #7c48ff;
        }
        &:active {
            background: #7038FF !important;
        }
    }

    &.btn-outline-info {
        border-color: #7038FF; 
        color: #7038FF; 

        &:hover {
            background: #7038FF;
            color: #ffffff;
        }
        &:active {
            background: #7038FF !important;
            color: #ffffff;
        }
    }

    &.btn-primary {
        background: linear-gradient(90deg, $cor-secondary-dark 0%, $cor-secondary 100%);
        border: none;

        &:hover {
            background: linear-gradient(90deg, $cor-principal-light 0%, $cor-secondary-dark 100%);
        }
    }

    
    &.btn-lg {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }
    
}