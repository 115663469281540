@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap');

$cor-principal: #4B4C9B;
$cor-principal-dark: #51525E;
$cor-principal-light: #713AFB;

$shadow: 0 0 10px #00000026;
$font-text: 'Montserrat', Arial, Helvetica, sans-serif;

$cor-secondary: #FFD700;
$cor-secondary-dark: #CC9F5A;
$cor-secondary-light: #FFD700;

$cor-participantMainBg: #5775D0;
$cor-participantSecondary: #579cef;


$cor-mainBg: #ffffff;
$cor-pageBg: #FaFaFA;

$cor-gray-10: #E2E2E2;
$cor-gray-25: #D5D5D5;
$cor-gray-50: #859096;
$cor-gray-60: #707070;
$cor-gray-75: #464749;
$cor-gray-90: #2F3538;

$cor-black: #000000;
$cor-white: #ffffff;

$cor-destaque-success: #17BC23;
$cor-destaque-danger: #D00000;

$cor-destaque-a: #57288A;
$cor-destaque-c: #34B7B5;
$cor-destaque-e: #FFF100;

$menu-height: 60px;

$brackpoint-sm: 576px;
$brackpoint-md: 768px;
$brackpoint-lg: 992px;
$brackpoint-xl: 1200px;