@import 'variaveis';


.table thead th {
    font-weight: 600;
    font-size: 14px;
} 
.table th {
    border-top: none;
}

.table td {
    font-size: 12px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.03);
}